import React from "react";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import '../../GallerySlider.css';

SwiperCore.use([Navigation, Pagination]);

const GallerySlider = () => {
  return (
    <section id="gallery" className="gallery-section">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        navigation
        pagination={{ clickable: true }}
        className="gallery-images"
      >
        <SwiperSlide>
          <img src={require("../../images/Bike1.jpg")} alt="Gallery 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("../../images/Bike2.jpg")} alt="Gallery 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("../../images/Bike3.jpg")} alt="Gallery 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("../../images/Bike4.jpg")} alt="Gallery 4" />
        </SwiperSlide>
        <SwiperSlide>
          <video
            controls
            width="100%"
            style={{ borderRadius: '20px' }}
          >
            <source src={require("../../images/Bike.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </SwiperSlide>

      </Swiper>
    </section>
  );
};

export default GallerySlider;
